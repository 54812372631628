<template>
  <div style="background: #f5f5f5;height: 100%;">
    <van-nav-bar title="调漆报价" :fixed="true" :z-index="999"></van-nav-bar>
    <div class="tai" v-if="isoktime">(共{{list.length}}台车)</div>
    <!-- style="padding-top:86px;" -->
    <div style="overflow: hidden;background: #f5f5f5;padding-top:60px" v-if="isoktime">
      <!-- <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoads"
        :offset="10"
      >-->
      <div class="pai_list" v-for="(item,idx) in list" :key="idx">
        <div class="h1">
          <span class="left">{{item.carNo}}</span>
          <span class="right texsubmit" v-if="item.offerStatus == 1">已提交</span>
        </div>
        <van-row v-for="(items,index) in item.items" :key="index">
          <van-col span="11">{{items.itemName}}</van-col>
          <van-col span="6">x{{items.counts}}</van-col>
          <van-col span="7" style="text-align: center;">
            <input
              class="ipus"
              v-if="item.offerStatus == 0"
              v-model="items.offerPrice"
              type="number"
            />
            <span v-else style="color: red;">{{items.offerPrice?items.offerPrice.toFixed(2):''}}</span>
          </van-col>
        </van-row>

        <div class="h2">
          <span
            class="left"
            v-if="item.offerStatus != 0"
          >提交时间：{{item.offerDate?item.offerDate.substr(5,11):''}}</span>
          <van-button
            class="right buts"
            v-if="item.offerStatus == 0"
            type="danger"
            size="mini"
            @click="submit(item)"
          >提交</van-button>
        </div>
      </div>
      <!-- </van-list> -->
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
    <div class="past" v-if="!isoktime">该页面已过期！</div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import { bplist, paintoffer } from "../../api/check";
export default {
  components: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      sename: "",
      search: "",
      isoktime: true,
      time: "",
      list: [],
      active: 1,
      count_down:0,
      gid: "",
      count: "",
      isok: false,
      loading: false,
      page: 0,
      size: 6,
      total: 0,
      finished: false
    };
  },
  methods: {
    submit(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认提交？"
        })
        .then(() => {
          var orderItems = [];
          for (var i in item.items) {
            if (
              item.items[i].offerPrice == "" ||
              item.items[i].offerPrice == null
            ) {
              var form = {
                id: item.items[i].itemId,
                offerPrice: 0
              };
            } else {
              var form = {
                id: item.items[i].itemId,
                offerPrice: item.items[i].offerPrice
              };
            }

            orderItems.push(form);
          }

          var data = {
            oid: item.oid,
            checkUserName: this.userInfo.realName,
            orderItems: orderItems
          };
          console.log(data);
          paintoffer(data).then(e => {
            if (e.code == 200) {
              this.$toast.success("提交成功！");
              this.onLoad();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },

    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      bplist({ gid: this.gid })
        .then(e => {
          loading.clear();

          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    appSource() {
      var _this = this
      var count_down = 0
    var dataTime = new Date(+new Date() + 8 * 3600 * 1000)
      .toJSON()
      .substr(0, 19)
      .replace("T", " ");
      const u = navigator.userAgent,
        app = navigator.appVersion;
      //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
       count_down =  this.datedifference(_this.time, dataTime,1);

        console.log("ios");
      } else {
      count_down =  this.datedifference(_this.time, dataTime,2);
        console.log("andriod");
      }
       return count_down;
    },
    datedifference(sDate1, sDate2,type) {
      //sDate1和sDate2是2006-12-18格式
      var dateSpan, tempDate, iDays;
      if(type == 1){
        sDate1 = sDate1.replace(/-/g,"/");
        sDate2 = sDate2.replace(/-/g,"/");
      }
  
      sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
        // alert(sDate1);
      // dateSpan = Math.abs(dateSpan);
      // console.log(dateSpan / 1000);
      iDays = dateSpan / 1000;
      // console.log(dateSpan / (24 * 3600 * 1000))
      // iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays;
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    function getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    }
    var _this = this;
    var search = window.location.search;
    this.search = window.location.href;
    this.time = getSearchString("time", search); //结果：18
    this.gid = getSearchString("gid", search); //结果：18
    var count_down = this.appSource()
    // alert(count_down)
// alert
    if (count_down > 3600) {
      this.isoktime = false;
      return;
    }
    console.log("走了这");
    if (localStorage.getItem("Authorization") == null) {
      // if (this.$route.query.denglu != undefined) {
      var url = this.$route.path + "?gid=" + this.$route.query.gid+"&time="+this.time;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then(e => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
      return;
    } else {
    }

    this.onLoad();
    // localStorage.setItem("token", this.token)
    // localStorage.setItem("token", 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjI4IiwiZXhwIjoxNjA4NzA2NzMzfQ.Qokbn2rZrv1Ri-g8tN7wqkVKbH0IVSrvd01ZbmP80DzJUf3sAhHn_nmax_fZiUr7edKSMripawYoVYrDqagJZw');
    // console.log(this.askPricePartIds)
  }
};
</script>

<style lang="less" scoped>
body {
  background: #f5f5f5;
}

.h1 {
  font-size: 18px;
  border-bottom: 1px solid #ebedf0;
  padding: 10px 0px;
  overflow: hidden;
}

.h2 {
  font-size: 15px;
  border-top: 1px solid #ebedf0;
  margin-top: 10px;
  padding-top: 10px;
  overflow: hidden;
  line-height: 24px;
  color: #666;
  
}

.pai_list {
  background: #fff;
  margin: 12px 12px;
  padding: 0px 12px 10px 12px;
  border-radius: 4px;
}
.texsubmit {
  color: red;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}
.van-col {
  line-height: 22px;

  font-size: 14px;
}

.ipus {
  height: 20px;
  width: 80px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 0 0.5px #dcdfe6;
  padding: 0 6px;
  color: #333;
}

.van-row {
  padding-left: 10px;
  padding-top: 10px;
}

.tai {
  text-align: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 46px;
  font-weight: bold;
  font-size: 14px;
  background: #f5f5f5;
  z-index: 99;
}
.past {
  text-align: center;
  color: red;
  font-size: 30px;
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -60px;
}
.buts {
  width: 70px;
  margin-right: 10px;
}
</style>
